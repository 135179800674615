import {useEffect, useMemo} from "react";
import {Mixpanel} from "../Mixpanel";
import Seo from "../seo";
import {Grid} from "@material-ui/core";
import MainMenu from "../MainMenu";
import Banner from "../Banner";
import ServiceList from "../ServiceList";
import OpenChat from "../OpenChat";
import AppFooter from "../AppFooter";
import * as React from "react";
import {slugify} from "../../lib/app_string";
import _ from "lodash";
import '../../styles/general.scss';


function ComponentOneService(props) {
    const {data, pageContext} = props;
    const prismicContact = data.prismicContact
    const allPrismicServices = data.allPrismicServices
    const testimonials = data.allPrismicTestimonial
    const {categoryId, categoryText, subCategory} = pageContext
    const mustOpenChat = new URLSearchParams(props.location.search).get('openChat');

    useEffect(() => {
        Mixpanel.track('Page-Prices');
        if (typeof mustOpenChat != "undefined" && mustOpenChat) {
            if (typeof window != "undefined") {
                window.openZohoChatProgramatically();
            }
        }
    }, [data])

    if (typeof categoryText == "undefined" || _.isEmpty(categoryText)) {
        // console.log("UNDEFINED", categoryText, categoryId, subCategory, pageContext);
    }
    let urlData = "";
    let description = "";
    let title = "";

    if (!_.isEmpty(pageContext)) {
        urlData = "https://www.cabinet-psiho.ro/services/" + slugify(categoryText.toLowerCase()) + "/";
        if (!_.isEmpty(subCategory)) {
            urlData = urlData + slugify(subCategory.toLowerCase()) + "/"
        }
        description = categoryText;
        if (!_.isEmpty(subCategory)) {
            description = description + ", " + subCategory;
        }
        description = description + ". Roxana Simionescu psiholog atestat Bucuresti"

        title = categoryText;
        if (!_.isEmpty(subCategory)) {
            title = title + ", " + subCategory;
        }
    } else {
        urlData = "https://www.cabinet-psiho.ro/services/";
        title = 'Servicii, Cabinet psiho, Roxana Simionescu psiholog atestat';
        description = 'Servicii, avize, sedinte online, Cabinet psihologic Roxana Simionescu psiholog atestat. Bucuresti psihoterapie, psihologie clinica, avize,  hipnoza,';
    }

    const seoData = {
        canonical: urlData,
        title: title,
        description: description,
        url: urlData,
        metas: [
        ]
    }

    const renderUsingMemo = () => (
        <div >
            <Seo title="Servicii si Preturi" seoData={seoData}/>
            <Grid container>
                <MainMenu pageName={"servicii_preturi"} prismicContact={prismicContact} />

                <Banner />

                {!_.isEmpty(pageContext) && (
                    <ServiceList
                        allPrismicServices={allPrismicServices}
                        pageContext={pageContext}
                        showTitle={true}
                        prismicContact={prismicContact}
                        testimonials={testimonials}
                        filterCategoryId={categoryId}
                        filterSubCategory={subCategory}
                        showSearch={false}
                        showDetails={true}
                    />
                )}
                {_.isEmpty(pageContext) && (
                    <ServiceList
                        allPrismicServices={allPrismicServices}
                        pageContext={pageContext}
                        showTitle={true}
                        prismicContact={prismicContact}
                        testimonials={testimonials}
                        showSearch={true}
                        showDetails={false}
                    />
                )}
            </Grid>

            {!_.isEmpty(pageContext) && (
                <div className={"w-full flex flex-row justify-content-center align-items-center align-middle mt-16 mb-8"}>
                    <a hrefLang="ro" href={"/services/"} className={"text_blue_link_color"}>Vezi toate serviciile</a>
                </div>
            )}

            <div className={"w-full flex flex-row justify-content-center align-items-center align-middle mt-16 mb-8"}>
                <OpenChat />
            </div>



            <AppFooter prismicContact={prismicContact}/>
        </div>
    )
    const memoizedValue = useMemo(() => renderUsingMemo(), [prismicContact]);
    return (<>{memoizedValue}</>)

}

export default ComponentOneService

